import api from "@/services/api.js";
import parseISO from "date-fns/parseISO";
import format from "date-fns/format";
import { toISO } from "@/lib/utils";

export const namespaced = true;

export const state = {
  loading: false,
  filterOptions: null,
  catalog: [],
  updating: false,
  dataSources: [],
  dataTypes: [],
};

export const mutations = {
  SET_LOADING_STATUS(state, value) {
    state.loading = value;
  },

  SET_CATALOG(state, data) {
    state.catalog = data;
  },

  SET_FILTER_OPTIONS(state, value) {
    state.filterOptions = value;
  },

  CLEAR_DATA(state) {
    state.filterOptions = null;
    state.loading = false;
    state.catalog = [];
    state.dataSources = [];
    state.dataTypes = [];
  },

  REMOVE_FROM_CATALOG(state, id) {
    for (let i = 0; i < state.catalog.length; i++) {
      const x = state.catalog[i];
      if (x.id == id) {
        state.catalog.splice(i, 1);
        break;
      }
    }
  },

  SET_UPDATING_STATUS(state, value) {
    state.updating = value;
  },

  SET_DATA_SOURCES(state, value) {
    // this feels backwards, but it's the way
    // they coded it and I'm tired of arguing

    state.dataSources = Object.keys(value.types_by_source).map((obj) => {
      return { ds: obj, dt: value.types_by_source[obj] };
    });

    state.dataTypes = Object.keys(value.sources_by_type).map((obj) => {
      return { dt: obj, ds: value.sources_by_type[obj] };
    });
  },
};

export const actions = {
  getCatalog(context, options) {
    let result = [];

    context.commit("SET_LOADING_STATUS", true);
    context.commit("SET_CATALOG", result);
    context.commit("SET_FILTER_OPTIONS", options);

    api
      .getDataCatalog(options)
      .then((response) => {
        const items = response.data.entries;
        let x;

        for (let i = 0; i < items.length; i++) {
          x = items[i];

          result.push({
            id: x.id,
            archive_url: x.archive_url,
            data_source: x.data_source,
            data_type: x.data_type,
            s3_key: x.bucket + "/" + x.key_name,
            created: format(parseISO(toISO(x.created)), "MM-dd-yyyy hh:mm aaa"),
            import_batch_id:
              "import_batch_id" in x.other_data
                ? x.other_data.import_batch_id
                : "",
            file_size:
              "file_size" in x.other_data ? x.other_data.file_size : "",
            min_trans_date:
              "min_trans_date" in x.other_data
                ? x.other_data.min_trans_date
                : "",
            max_trans_date:
              "max_trans_date" in x.other_data
                ? x.other_data.max_trans_date
                : "",
            record_count:
              "record_count" in x.other_data ? x.other_data.record_count : "",
            total_sales:
              "total_sales" in x.other_data ? x.other_data.total_sales : "",
            other_data: x.other_data,
          });
        }

        if (options.sorting && options.sorting === "desc") {
          result = result.sort((a, b) => parseInt(b.id) - parseInt(a.id));
        }

        context.commit("SET_LOADING_STATUS", false);
        context.commit("SET_CATALOG", result);
      })
      .catch(() => {
        context.commit("SET_LOADING_STATUS", false);
      });
  },

  clearCatalog(context) {
    context.commit("CLEAR_DATA");
  },

  updateStatus(context, payload) {
    context.commit("SET_UPDATING_STATUS", true);

    api
      .updateDataCatalogStatus(payload.id, payload.status)
      .then(() => {
        context.commit("REMOVE_FROM_CATALOG", payload.id);
        context.commit("SET_UPDATING_STATUS", false);
      })
      .catch(() => {
        context.commit("SET_UPDATING_STATUS", false);
      });
  },

  removeImportBatch(context, payload) {
    if (context.state.catalog) {
      context.commit("SET_UPDATING_STATUS", true);
      context.commit("SET_LOADING_STATUS", true);

      context.state.catalog.forEach((item) => {
        if (item.import_batch_id === payload.import_batch_id) {
          api
            .updateDataCatalogStatus(item.id, "R")
            .then(() => {
              context.commit("REMOVE_FROM_CATALOG", item.id);
              context.commit("SET_UPDATING_STATUS", false);
            })
            .catch(() => {
              context.commit("SET_UPDATING_STATUS", false);
            });
        }
      });
    }

    context.commit("SET_LOADING_STATUS", false);
  },

  getDataSources(context) {
    context.commit("SET_LOADING_STATUS", true);

    api
      .getDataSources()
      .then((response) => {
        context.commit("SET_LOADING_STATUS", false);
        context.commit("SET_DATA_SOURCES", response.data);
      })
      .catch(() => {
        context.commit("SET_UPDATING_STATUS", false);
      });
  },
};

export const getters = {
  getFilterOptions() {
    return state.filterOptions;
  },

  isLoading() {
    return state.loading;
  },

  getCatalog() {
    return state.catalog;
  },

  isUpdating() {
    return state.updating;
  },

  getDataSources() {
    return state.dataSources;
  },

  getDataTypes() {
    return state.dataTypes;
  },
};
