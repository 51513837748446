import Vue from "vue";
// import axios from "axios";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import vuetify from "./plugins/vuetify";
import Vuelidate from "vuelidate";
import VModal from "vue-js-modal";
import MessageBox from "@/components/utils/MessageBox";
import "prismjs";
import "prismjs/themes/prism.css";

Vue.use(Vuelidate);

Vue.use(VModal, {
  dynamic: true,
  injectModalsContainer: true,
  dynamicDefaults: { clickToClose: true },
});

Vue.component("sr-msg-box", MessageBox);
Vue.prototype.$eventHub = new Vue();

Vue.config.productionTip = false;

new Vue({
  router,
  store,
  vuetify,

  created() {
    this.$vuetify.theme.themes.dark.primary = "#3C8DCC";
    this.$vuetify.theme.themes.light.primary = "#3C8DCC";

    const userString = localStorage.getItem("user");
    if (userString) {
      const userData = JSON.parse(userString);
      this.$store.commit("user/SET_USER_DATA", userData);
      // api.checkToken();
    }

    this.$vuetify.theme.dark = true;

    const uiString = localStorage.getItem("ui");
    if (uiString) {
      const ui = JSON.parse(uiString);
      this.$store.dispatch("ui/setOptions", ui);
    }
  },
  render: (h) => h(App),
}).$mount("#app");
