export const namespaced = true;

export const state = {
  options: {},
};

export const mutations = {
  SET_OPTIONS(state, data) {
    state.options = { ...state.options, ...data };
    localStorage.setItem("ui", JSON.stringify(state.options));
  },
};

export const actions = {
  setOptions(context, data) {
    context.commit("SET_OPTIONS", data);
  },
};

export const getters = {
  getOptions(state) {
    return state.options;
  },
};
