import Vue from "vue";
import Vuetify from "vuetify/lib";

export default new Vuetify({
  theme: {
    dark: false,
    primary: "#FFA726",
  },
});

Vue.use(Vuetify);
