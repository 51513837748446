<template>
  <v-dialog
    v-model="dialog"
    :max-width="options.width"
    :style="{ zIndex: options.zIndex }"
    @keydown.esc="closeBox"
    @keydown.enter="closeBox"
  >
    <v-card>
      <v-toolbar dark :color="options.color" dense flat>
        <v-toolbar-title class="white--text">{{ title }}</v-toolbar-title>
      </v-toolbar>
      <v-card-text v-show="!!message" class="pa-4">{{ message }}</v-card-text>
      <v-card-actions class="pt-0">
        <v-spacer></v-spacer>
        <v-btn ref="btnOK" color="primary darken-1" text @click="closeBox"
          >OK</v-btn
        >
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
/*
    code based on https://gist.github.com/eolant/ba0f8a5c9135d1a146e1db575276177d
    Credit to eolant
*/

export default {
  data: () => ({
    dialog: false,
    message: null,
    title: null,
    closeCallback: null,
    options: {
      color: "primary",
      width: 290,
      zIndex: 200,
    },
  }),

  methods: {
    open(title, message, options) {
      this.dialog = true;
      this.title = title;
      this.message = message;
      this.options = Object.assign(this.options, options);

      setTimeout(() => {
        this.$refs.btnOK.$el.focus();
      });

      return new Promise((resolve) => {
        this.closeCallback = resolve;
      });
    },

    closeBox() {
      this.dialog = false;
      if (this.closeCallback) {
        this.closeCallback();
      }
    },
  },
};
</script>
