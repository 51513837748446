import parseISO from "date-fns/parseISO";
import format from "date-fns/format";
import subMinutes from "date-fns/subMinutes";
import startOfWeek from "date-fns/startOfWeek";
import subDays from "date-fns/subDays";
import startOfMonth from "date-fns/startOfMonth";
import startOfQuarter from "date-fns/startOfQuarter";
import startOfYear from "date-fns/startOfYear";
import subMonths from "date-fns/subMonths";
import subYears from "date-fns/subYears";
import startOfToday from "date-fns/startOfToday";

export const shortDateFormatStr = "MM-dd-yyyy";
export const longDateFormatStr = "MM-dd-yyyy hh:mm:ss aaa";
export const reISO = /^\d{4}-\d{2}-\d{2}\s\d{2}:\d{2}:\d{2}/;
export const emailRegex =
  /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
export const phoneRegex = /^(\()?\d{3}(\))?(-|\s)?\d{3}(-|\s)\d{4}$/;
export const uploadConfigDocsURL =
  "https://google.com";

export const stateAbbr = [
  "AL",
  "AK",
  "AS",
  "AZ",
  "AR",
  "CA",
  "CO",
  "CT",
  "DE",
  "DC",
  "FM",
  "FL",
  "GA",
  "GU",
  "HI",
  "ID",
  "IL",
  "IN",
  "IA",
  "KS",
  "KY",
  "LA",
  "ME",
  "MH",
  "MD",
  "MA",
  "MI",
  "MN",
  "MS",
  "MO",
  "MT",
  "NE",
  "NV",
  "NH",
  "NJ",
  "NM",
  "NY",
  "NC",
  "ND",
  "MP",
  "OH",
  "OK",
  "OR",
  "PW",
  "PA",
  "PR",
  "RI",
  "SC",
  "SD",
  "TN",
  "TX",
  "UT",
  "VT",
  "VI",
  "VA",
  "WA",
  "WV",
  "WI",
  "WY",
  "XX",
];

const moneyFormatter = new Intl.NumberFormat("en-US", {
  style: "currency",
  currency: "USD",
  minimumFractionDigits: 2,
});

const numberFormatter = new Intl.NumberFormat("en", {
  notation: "standard",
});

export function toISO(value) {
  if (reISO.test(value)) {
    return value.replace(" ", "T");
  } else {
    return value;
  }
}

export function formatDateTime(value, defaultValue) {
  try {
    if (/\d/.test(value)) {
      return format(parseISO(toISO(value)), longDateFormatStr);
    } else {
      return defaultValue ? defaultValue : "";
    }
  } catch {
    return "";
  }
}

export function formatDate(value, defaultValue) {
  try {
    if (/\d/.test(value)) {
      return format(parseISO(toISO(value)), shortDateFormatStr);
    } else {
      return defaultValue ? defaultValue : "";
    }
  } catch {
    return "";
  }
}

export function formatMoney(value) {
  if (/\d/.test(value)) {
    return moneyFormatter.format(value);
  }

  return value;
}

export function formatNumber(value) {
  if (/\d/.test(value)) {
    return numberFormatter.format(value);
  }

  return value;
}

export function isValidRegExp(value) {
  try {
    return Boolean(new RegExp(value));
  } catch (err) {
    return false;
  }
}

export function objectToKeyValues(obj) {
  let result = "";

  Object.keys(obj).forEach((k) => {
    let v = obj[k];
    if (v instanceof Date) {
      result = result + k + ": " + v.toISOString() + ", ";
    } else {
      result = result + k + ": " + v + ", ";
    }
  });

  return result.slice(0, -2);
}

export function textToDate(value) {
  if (value instanceof Date) {
    return value;
  }

  const curDate = new Date();
  let result = null;

  switch (value.toLowerCase()) {
    case "1 minute":
      result = subMinutes(curDate, 1);
      break;
    case "5 minutes":
      result = subMinutes(curDate, 5);
      break;
    case "10 minutes":
      result = subMinutes(curDate, 10);
      break;
    case "30 minutes":
      result = subMinutes(curDate, 30);
      break;
    case "1 hour":
      result = subMinutes(curDate, 60);
      break;
    case "8 hours":
      result = subMinutes(curDate, 60 * 8);
      break;
    case "12 hours":
      result = subMinutes(curDate, 60 * 12);
      break;
    case "today":
      result = startOfToday();
      break;
    case "1 day":
      result = subDays(curDate, 1);
      break;
    case "24 hours":
      result = subDays(curDate, 1);
      break;
    case "this week":
      result = startOfWeek(curDate);
      break;
    case "1 week":
      result = subDays(curDate, 7);
      break;
    case "2 weeks":
      result = subDays(curDate, 14);
      break;
    case "this month":
      result = startOfMonth(curDate);
      break;
    case "1 month":
      result = subMonths(curDate, 1);
      break;
    case "mtd":
      result = subMonths(curDate, 1);
      break;
    case "45 days":
      result = subDays(curDate, 45);
      break;
    case "this quarter":
      result = startOfQuarter(curDate);
      break;
    case "3 months":
      result = subMonths(curDate, 3);
      break;
    case "this year":
      result = startOfYear(curDate);
      break;
    case "1 year":
      result = subYears(curDate, 1);
      break;
    case "ytd":
      result = subYears(curDate, 1);
      break;
    default:
      throw "Unknown date string: " + value;
  }
  return result;
}

export function getUploadConfigHelpURL(section) {
  if (section) {
    return `${uploadConfigDocsURL}#${section}`;
  } else {
    return "";
  }
}

export function ruleFails(rule, value) {
  let result = rule(value);

  if (typeof result === "string" || result instanceof String) {
    return true;
  }

  return false;
}

export function isString(value) {
  return typeof value === "string" || value instanceof String;
}

export function isInteger(value) {
  var x;
  if (isNaN(value)) {
    return false;
  }
  x = parseFloat(value);
  return (x | 0) === x;
}

export function hasValue(value) {
  if (value instanceof Array && value.length == 0) {
    return false;
  }

  if (value instanceof String && !!value) {
    return false;
  }

  if (value instanceof Date && isNaN(value.getTime())) {
    return false;
  }

  if (value === undefined || value === null) {
    return false;
  }

  if (value === false) {
    return true;
  }

  if (typeof value === "object") {
    for (let _ in value) return true;
    return false;
  }

  return !!String(value).length;
}

export function isValidDomain(value) {
  if (isString(value)) {
    return /^(([a-zA-Z]{1})|([a-zA-Z]{1}[a-zA-Z]{1})|([a-zA-Z]{1}[0-9]{1})|([0-9]{1}[a-zA-Z]{1})|([a-zA-Z0-9][a-zA-Z0-9-_]{1,61}[a-zA-Z0-9]))\.([a-zA-Z]{2,6}|[a-zA-Z0-9-]{2,30}\.[a-zA-Z]{2,3})$/i.test(
      value
    );
  }

  return false;
}

export function isValidEmail(value) {
  if (isString(value)) {
    return emailRegex.test(value);
  }

  return false;
}

export function isValidPhone(value) {
  if (isString(value)) {
    return phoneRegex.test(value);
  }

  return false;
}

export function deepEqual(a, b) {
  if (a === b) return true;

  if (a instanceof Date && b instanceof Date) {
    // If the values are Date, they were convert to timestamp with getTime and compare it
    if (a.getTime() !== b.getTime()) return false;
  }

  if (a !== Object(a) || b !== Object(b)) {
    // If the values aren't objects, they were already checked for equality
    return false;
  }

  const props = Object.keys(a);

  if (props.length !== Object.keys(b).length) {
    // Different number of props, don't bother to check
    return false;
  }

  return props.every((p) => deepEqual(a[p], b[p]));
}

export function lowerCase(item) {
  if (isString(item)) {
    return item.toLowerCase();
  }
}

export function formatExtension(item) {
  if (isString(item) && !item.startsWith(".")) {
    return `.${item}`;
  } else {
    return item;
  }
}

export function hasErrors(rules, currentValue) {
  for (let i = 0; i < rules.length; i++) {
    const rule = rules[i];
    const e = isString(rule(currentValue));
    if (e) {
      return true;
    }
  }
}

export function swapArrayElements(arr, idx1, idx2) {
  const e = arr[idx1];
  arr[idx1] = arr[idx2];
  arr[idx2] = e;
  return arr;
}

export function moveUp(arr, idx) {
  if (!arr) {
    return arr;
  }

  if (idx <= 0 || idx > arr.length - 1) {
    return arr;
  }

  return swapArrayElements(arr, idx, idx - 1);
}

export function moveDown(arr, idx) {
  if (!arr) {
    return arr;
  }

  if (idx < 0 || idx >= arr.length - 1) {
    return arr;
  }

  return swapArrayElements(arr, idx, idx + 1);
}
