import api from "@/services/api.js";

export const namespaced = true;

export const state = {
  batchInfo: [],
  batchInfoLoading: false,
};

export const mutations = {
  SET_BATCH_INFO_LOADING_STATUS(state, value) {
    state.batchInfoLoading = value;
  },

  SET_BATCH_INFO(state, value) {
    state.batchInfo = value;
  },

  REMOVE_BATCH_INFO(state, value) {
    state.batchInfo = state.batchInfo.filter(
      (b) => b.import_batch_id !== value
    );
  },
};

export const actions = {
  getBatchInfo(context, options) {
    context.commit("SET_BATCH_INFO_LOADING_STATUS", true);
    context.commit("SET_BATCH_INFO", []);

    api
      .getBatchInfo("retail-1", options)
      .then((response) => {
        context.commit("SET_BATCH_INFO", response.data.rows);
        context.commit("SET_BATCH_INFO_LOADING_STATUS", false);
      })
      .catch(() => {
        context.commit("SET_BATCH_INFO_LOADING_STATUS", false);
      });
  },

  removeBatch(context, options) {
    context.commit("SET_BATCH_INFO_LOADING_STATUS", true);

    api
      .removeBatch("retail-1", options)
      .then(() => {
        context.commit("SET_BATCH_INFO_LOADING_STATUS", false);
        context.commit("REMOVE_BATCH_INFO", options.import_batch_id);
      })
      .catch(() => {
        context.commit("SET_BATCH_INFO_LOADING_STATUS", false);
      });
  },

  clearBatchInfo(context) {
    context.commit("SET_BATCH_INFO", []);
  },
};

export const getters = {
  getBatches() {
    return state.batchInfo;
  },

  isBatchInfoLoading() {
    return state.batchInfoLoading;
  },
};
