import api from "@/services/api.js";
import formatISO from "date-fns/formatISO";

export const namespaced = true;

export const state = {
  loading: false,
  details: null,
  uploading: false,
  processInfo: "",
};

export const mutations = {
  SET_LOADING_STATUS(state, value) {
    state.loading = value;
  },

  SET_UPLOADING_STATUS(state, value) {
    state.uploading = value;
  },

  SET_DATA(state, data) {
    if (
      data.last_run === "unknown" ||
      data.last_run === "" ||
      data.last_run === "disabled"
    ) {
      data.last_run = null;
    }

    if (
      data.next_run === "unknown" ||
      data.next_run === "" ||
      data.next_run === "disabled"
    ) {
      data.next_run = null;
    }

    state.details = data;
  },

  CLEAR_DATA(state) {
    state.details = null;
    state.uploading = false;
    state.processInfo = "";
  },

  ADD_FILE(state, payload) {
    // if the file already exists, update the values

    const fn = payload.fileSelected.name;
    const fs = payload.fileSelected.size;
    const fd = formatISO(new Date());

    for (let i = 0; i < state.details.pending_files.length; i++) {
      const f = state.details.pending_files[i];
      if (f.name == fn) {
        (f.size = fs), (f.mod_time = fd);
        return;
      }
    }

    // if not in the list, then add it to the array
    state.details.pending_files.push({
      name: fn,
      size: fs,
      mod_time: fd,
      error: null,
    });
  },

  REMOVE_FILE(state, fileName) {
    state.details.pending_files = state.details.pending_files.filter(function (
      obj
    ) {
      return obj.name !== fileName;
    });
  },

  ADD_PROCESS_INFO(state, value) {
    state.processInfo = state.processInfo + value;
  },

  CLEAR_PROCESS_INFO(state) {
    state.processInfo = "";
  },
};

export const actions = {
  getDetails(context, name) {
    context.commit("CLEAR_DATA");
    context.commit("SET_LOADING_STATUS", true);

    return api.getUploadDetails(name).then((response) => {
      context.commit("SET_DATA", response.data);
      context.commit("SET_LOADING_STATUS", false);
    });
  },

  deleteFile(context, payload) {
    return api
      .deleteUploadFile(payload.pipelineSlug, payload.fileName)
      .then(() => {
        context.commit("REMOVE_FILE", payload.fileName);
      });
  },

  uploadFile(context, payload) {
    context.commit("SET_UPLOADING_STATUS", true);

    return api
      .uploadFile(
        payload.pipelineSlug,
        payload.fileSelected.name,
        payload.data,
        payload.progressCallback
      )
      .then(() => {
        //console.log("in then, payload: ", payload);
        context.commit("ADD_FILE", payload);
        context.commit("SET_UPLOADING_STATUS", false);
      });
  },

  clearData(context) {
    context.commit("CLEAR_DATA");
  },

  publish(context, payload) {
    api.publish(payload).then((response) => {
      const reader = response.body.getReader();
      const decoder = new TextDecoder();

      // read() returns a promise that resolves
      // when a value has been received
      reader.read().then(function processText({ done, value }) {
        // Result objects contain two properties:
        // done  - true if the stream has already given you all its data.
        // value - some data. Always undefined when done is true.
        if (done) {
          return;
        }

        context.commit(
          "ADD_PROCESS_INFO",
          decoder.decode(value, { stream: true })
        );

        // Read some more, and call this function again
        return reader.read().then(processText);
      });
    });
  },

  clearPublishInfo(context) {
    context.commit("CLEAR_PROCESS_INFO");
  },
};

export const getters = {
  isLoading() {
    return state.loading;
  },

  isUploading() {
    return state.uploading;
  },

  getDetails() {
    return state.details;
  },

  getProcessInfo() {
    return state.processInfo;
  },
};
