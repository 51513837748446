import axios from "axios";
import router from "@/router/index.js";
import store from "@/store/index.js";
import { textToDate } from "@/lib/utils";

const apiClient = axios.create({
  baseURL: process.env.VUE_APP_URL,
  withCredentials: false,

  headers: {
    Accept: "application/json",
    "Content-Type": "application/json",
  },
});

apiClient.interceptors.request.use(
  (config) => {
    const userString = localStorage.getItem("user");
    if (userString) {
      const userData = JSON.parse(userString);
      if (userData.token) {
        config.headers.common["Authorization"] = "Bearer " + userData.token;
      }
    }

    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

const defaultClientName = "artemis";

function pushNotification(error) {
  // this can happen if you have a network error and
  // there is no response

  if (!error.response) {
    return true;
  }

  if (!error.response.config) {
    return true;
  }

  return !error.response.config.ignoreError;
}

apiClient.interceptors.response.use(
  function (response) {
    // Do something with response data
    return response;
  },
  function (error) {
    if (error && error.response && error.response.status === 401) {
      if (
        router.currentRoute.name !== "login" &&
        router.currentRoute.name !== "goodbye"
      ) {
        router.push({
          name: "login",
          query: { redirect: router.currentRoute.path },
        });
      }
    } else {
      if (pushNotification(error)) {
        const notification = {
          type: "error",
          message: error.message,
          details: error.data,
          error: error,
          timeout: 60000,
        };

        //console.log(notification);
        store.dispatch("notification/add", notification, { root: true });
      }
    }

    return Promise.reject(error);
  }
);

export default {
  // User

  login(credentials) {
    return apiClient.post("/api/user/login", credentials);
  },

  signout() {
    return apiClient.get("/api/user/logout");
  },

  changePassword(data, config) {
    return apiClient.put("/api/user/change-password", data, config);
  },

  register(data) {
    return apiClient.put("/api/user/register", data);
  },

  sendPasswordResetRequest(data) {
    return apiClient.put("/api/user/reset-password", data);
  },

  resetPassword(data) {
    return fetch(process.env.VUE_APP_URL + `/api/user/change-password`, {
      method: "put",

      body: JSON.stringify({
        password: data.password,
      }),

      headers: new Headers({
        Authorization: "Bearer " + data.token,
        "Content-Type": "application/json",
      }),
    });
  },

  // have to have a "pings" (secure pint) call to try and see if server
  // thinks the token is still valid. This is because the system doesn't
  // persist tokens.
  checkToken() {
    return apiClient.get("/api/pings");
  },

  //pubhub calls

  getDataCatalog(options) {
    const q = {};

    q["from-date"] = textToDate(options.fromDate);

    if (options.dataType) {
      q["data-type"] = options.dataType;
    }

    if (options.dataSource) {
      q["data-source"] = options.dataSource;
    }

    if (options.archiveURL) {
      q["archive-url"] = "yes";
    } else {
      q["archive-url"] = "no";
    }

    if (options.importBatchID) {
      q["import-batch-id"] = options.importBatchID;
    }

    return apiClient.get("/api/pubhub", { params: q });
  },

  getDataCatalogByID(catalogID) {
    return apiClient.get("/api/pubhub/" + catalogID);
  },

  updateDataCatalogStatus(catalogID, status) {
    return apiClient.patch("/api/pubhub/" + catalogID, { status: status });
  },

  getDataSources() {
    return apiClient.get("/api/pubhub");
  },

  // Upload server calls

  getUploadStatus() {
    return apiClient.get("/api/upload");
  },

  getUploadDetails(name) {
    return apiClient.get("/api/upload/" + name);
  },

  deleteUploadFile(source, fileName) {
    return apiClient.delete(`/api/upload/${source}/pending-file/${fileName}`);
  },

  getUploadFile(source, fileName) {
    const config = {
      responseType: "blob",
      timeout: 30000,
    };

    return apiClient.get(
      `/api/upload/${source}/pending-file/${fileName}`,
      config
    );
  },

  renameUploadFile(source, oldFileName, newFileName) {
    return apiClient.patch(
      `/api/upload/${source}/pending-file/${oldFileName}`,
      { new_name: newFileName }
    );
  },

  uploadFile(source, fileName, formData, progressCallBack) {
    return apiClient.put(
      `/api/upload/${source}/pending-file/${fileName}`,
      formData,
      { onUploadProgress: progressCallBack }
    );
  },

  uploadTheaterContract(
    contractID,
    year,
    fileName,
    formData,
    progressCallBack
  ) {
    // PUT /api/theater/:id/contract/:year
    return apiClient.put(
      `/api/theater/${contractID}/contract/${year}/${fileName}`,
      formData,
      { onUploadProgress: progressCallBack }
    );
  },

  publish(source) {
    const userString = localStorage.getItem("user");
    if (userString) {
      const userData = JSON.parse(userString);
      if (userData.token) {
        return fetch(
          process.env.VUE_APP_URL + `/api/upload/${source}/publisher`,
          {
            method: "get",
            headers: new Headers({
              Authorization: "Bearer " + userData.token,
            }),
          }
        );
      }
    }
  },

  getUploadConfig(pipeline) {
    return apiClient.get(`api/upload/${pipeline}/config`);
  },

  updateUploadConfig(pipeline, data) {
    return apiClient.put(`api/upload/${pipeline}/config`, data);
  },

  getDashboardList() {
    return apiClient.get("/api/dashboard");
  },

  getDashboardEmbeddedURL(code) {
    return apiClient.get(`/api/dashboard/${code}`);
  },

  throw404() {
    return apiClient.get("/api/pingsx", { params: { a: 3, b: 5 } });
  },

  getBatchInfo(dbName, data, client = defaultClientName) {
    return apiClient.post(`api/db/${client}/${dbName}/get_batch_info`, data);
  },

  removeBatch(dbName, data, client = defaultClientName) {
    return apiClient.post(
      `api/db/${client}/${dbName}/remove_import_batch`,
      data
    );
  },

  getPendingSaleSummary(data, client = defaultClientName) {
    return apiClient.post(
      `api/db/${client}/etl-2/get_pending_sale_summary`,
      data
    );
  },

  getOpenIssueSummary(data, client = defaultClientName) {
    return apiClient.post(
      `api/db/${client}/etl-2/get_open_issue_summary`,
      data
    );
  },

  getCustomerMappingSuggestions(data, client = defaultClientName) {
    return apiClient.post(
      `api/db/${client}/etl-2/get_customer_mapping_suggestions`,
      data
    );
  },

  getItemMappingSuggestions(data, client = defaultClientName) {
    return apiClient.post(
      `api/db/${client}/etl-2/get_item_mapping_suggestions`,
      data
    );
  },

  getItemPackagings(data, client = defaultClientName) {
    return apiClient.post(`api/db/${client}/etl-2/get_item_packaging`, data);
  },

  getFkData(data, client = defaultClientName) {
    return apiClient.post(`api/db/${client}/etl-2/get_fk_data`, data);
  },

  createSaleProcessingIssueResolution(data, client = defaultClientName) {
    return apiClient.post(
      `api/db/${client}/etl-2/create_sale_processing_issue_resolution`,
      data
    );
  },

  getWarehouse(data, client = defaultClientName) {
    return apiClient.post(`api/db/${client}/etl-2/get_warehouse`, data);
  },

  callDBFunc(dbName, funcName, data, client = defaultClientName) {
    return apiClient.post(`api/db/${client}/${dbName}/${funcName}`, data);
  },
};
