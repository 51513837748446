import Vue from "vue";
import VueRouter from "vue-router";
import Home from "../views/Home.vue";
import store from "@/store";
import NotFound from "@/views/NotFound.vue";

Vue.use(VueRouter);

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,

  routes: [
    {
      path: "/",
      name: "home",
      component: Home,
    },
    {
      path: "/about",
      name: "about",
      component: () =>
        import(/* webpackChunkName: "about" */ "../views/About.vue"),
    },
    {
      path: "/login",
      name: "login",
      component: () =>
        import(/* webpackChunkName: "login" */ "../views/user/Login.vue"),
    },
    {
      path: "/signup",
      name: "signup",
      component: () => import("../views/user/Signup.vue"),
    },
    {
      path: "/logout",
      name: "logout",
      meta: { requiresAuth: true },
      component: () => import("../views/user/Logout.vue"),
    },
    {
      path: "/change-password",
      name: "changePassword",
      meta: { requiresAuth: true },
      component: () => import("../views/user/ChangePassword.vue"),
    },
    {
      path: "/forgot-password",
      name: "forgotPassword",
      component: () => import("../views/user/ForgotPassword.vue"),
    },
    {
      path: "/reset-password",
      name: "resetPassword",
      component: () => import("../views/user/ResetPassword.vue"),
    },
    {
      path: "/goodbye",
      name: "goodbye",
      component: () => import("../views/user/Goodbye.vue"),
    },
    {
      path: "/registration-pending",
      name: "registration-pending",
      component: () => import("../views/user/RegistrationPending.vue"),
    },
    {
      path: "/verify-email",
      name: "verify-email",
      component: () => import("../views/user/VerifyEmail.vue"),
    },

    {
      path: "/upload-server",
      name: "UploadServer",
      meta: { requiresAuth: true },
      component: () => import("../views/upload/UploadStatus.vue"),
    },

    {
      path: "/upload-server/:pipelineSlug",
      name: "UploadDetails",
      props: true,
      meta: { requiresAuth: true },
      component: () => import("../views/upload/UploadDetails.vue"),
    },

    {
      path: "/warehouse/:id",
      name: "Warehouse",
      props: true,
      meta: { requiresAuth: true },
      component: () => import("../views/warehouse/Warehouse.vue"),
    },

    {
      path: "/upload-server/:pipelineSlug/config",
      name: "UploadConfig",
      props: true,
      meta: { requiresAuth: true },
      component: () => import("../views/upload/UploadConfigEditor.vue"),
    },

    {
      path: "/pubhub/",
      name: "Pubhub",
      props: true,
      meta: { requiresAuth: true },
      component: () => import("../views/pubhub/PubhubDetails.vue"),
    },

    {
      path: "/batch-info",
      name: "BatchInfo",
      props: true,
      meta: { requiresAuth: true },
      component: () => import("../views/batch/BatchDetails2.vue"),
    },

    {
      path: "/pending-sales/summary",
      name: "PendingSales",
      props: true,
      meta: { requiresAuth: true },
      component: () => import("../views/pending/Summary.vue"),
    },

    {
      path: "/sale-processing-issue/summary",
      name: "SaleProcessingIssue",
      props: true,
      meta: { requiresAuth: true },
      component: () => import("../views/issues/Summary.vue"),
    },

    {
      path: "/sale-processing-issue/customer-mapping",
      name: "CustomerMappingIssue",
      meta: { requiresAuth: true },
      component: () => import("../views/issues/CustomerMapping.vue"),
    },

    // {
    //   path: "/sale-processing-issue/item-mapping",
    //   name: "ItemMappingIssue",
    //   meta: { requiresAuth: true },
    //   component: () => import("../views/issues/ItemMapping.vue"),
    // },

    {
      path: "/resolutions/customer-mapping",
      name: "CustomerMappingResolutions",
      meta: { requiresAuth: true },
      component: () =>
        import("../views/resolutions/CustomerMappingResolutions.vue"),
    },

//   {
//      path: "/theater/contract",
//     name: "TheaterContract",
//      meta: { requiresAuth: true },
//      component: () => import("../views/theater/Contract.vue"),
//    },

    {
      path: "/changelog",
      name: "ChangeLog",
      meta: { requiresAuth: true },
      component: () => import("../views/ChangeLog.vue"),
    },

    // {
    //   path: "/etl-1",
    //   name: "ETL1",
    //   props: true,
    //   meta: { requiresAuth: true },
    //   component: () => import("../views/ComingSoon.vue")
    // },

    // {
    //   path: "/etl-2",
    //   name: "ETL2",
    //   props: true,
    //   meta: { requiresAuth: true },
    //   component: () => import("../views/ComingSoon.vue")
    // },

    // {
    //   path: "/db-1",
    //   name: "DB1",
    //   props: true,
    //   meta: { requiresAuth: true },
    //   component: () => import("../views/ComingSoon.vue")
    // },

    // {
    //   path: "/fsdb",
    //   name: "FSDB",
    //   props: true,
    //   meta: { requiresAuth: true },
    //   component: () => import("../views/ComingSoon.vue")
    // },

    // {
    //   path: "/spdb",
    //   name: "SPDB",
    //   props: true,
    //   meta: { requiresAuth: true },
    //   component: () => import("../views/ComingSoon.vue")
    // },

    {
      path: "/dashboard/",
      name: "dashboard",
      props: true,
      meta: { requiresAuth: true },
      component: () => import("../views/dashboard/Dashboard.vue"),
    },

    {
      path: "/testing",
      name: "testing",
      props: true,
      meta: { requiresAuth: true },
      component: () => import("../views/Testing.vue"),
    },

    {
      path: "/404",
      name: "404",
      component: NotFound,
    },

    {
      // Here's the new catch all route
      path: "*",
      redirect: { name: "404" },
    },
  ],
});

router.beforeEach((to, from, next) => {
  const loggedIn = localStorage.getItem("user");

  // clear any notifications off the screen before
  // going to the next route
  store.dispatch("notification/clear");

  if (to.matched.some((record) => record.meta.requiresAuth) && !loggedIn) {
    next({ name: "login", query: { redirect: to.fullPath } });
  } else {
    next();
  }
});

export default router;
