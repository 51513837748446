<template>
  <v-app>
    <Navbar />
    <v-main>
      <NotificationContainer />

      <transition name="slide-fade" mode="out-in">
        <router-view />
      </transition>
    </v-main>
  </v-app>
</template>

<script>
import Navbar from "@/components/utils/Navbar.vue";
import NotificationContainer from "@/components/utils/NotificationContainer.vue";

export default {
  name: "App",

  components: {
    Navbar,
    NotificationContainer,
  },

  data() {
    return {};
  },
  methods: {},
};
</script>

<style>
/*** TRANSITIONS ***/
.fade-enter {
  opacity: 0;
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s ease-out;
}

.fade-leave-to {
  opacity: 0;
}

.slide-fade-enter {
  transform: translateX(30px);
  opacity: 0;
}

.slide-fade-enter-active,
.slide-fade-leave-active {
  transition: all 0.4s ease;
}

.slide-fade-leave-to {
  transform: translateX(-30px);
  opacity: 0;
}
</style>
