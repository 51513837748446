<template>
  <v-container fluid fill-height fill-width>
    <v-layout align-center justify-center>
      <v-flex xs12 sm8 md4>
        <v-row>
          <v-col col="12">
            <h1 class="display-4 font-weight-bold text-center">{{ ticks }}</h1>
            <transition name="fade">
              <h1 v-if="show" class="text-center">Oops!</h1>
            </transition>
            <transition name="fade">
              <h3 v-if="show" class="text-center">
                The page you are looking was not found!
              </h3>
            </transition>
          </v-col>
        </v-row>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
export default {
  _intervalID: null,

  data() {
    return {
      ticks: 1,
      show: false,
    };
  },

  methods: {
    tick() {
      this.ticks++;
      if (this.ticks === 404) {
        clearInterval(this._intervalID);
        this.show = true;
      }
    },
  },

  mounted() {
    this._intervalID = setInterval(this.tick, 3);
  },

  beforeDestroy() {
    if (this._intervalID) {
      clearInterval(this._intervalID);
    }
  },
};
</script>

<style scoped>
.fade-enter-active,
.fade-leave-active {
  transition: opacity 3.5s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}
</style>
