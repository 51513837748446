import api from "@/services/api.js";

export const namespaced = true;

export const state = {
  boards: [],
  loadingBoards: false,
  retrievingEmbeddedURL: false,
  currentURL: null,
};

export const mutations = {
  SET_BOARDS(state, data) {
    state.boards = data;
  },

  CLEAR_DATA(state) {
    state.boards = [];
  },

  SET_LOADING_BOARDS_STATUS(state, value) {
    state.loadingBoards = value;
  },

  SET_RETRIEVING_EMBEDDED_URL(state, value) {
    state.retrievingEmbeddedURL = value;
  },

  SET_EMBEDDED_URL(state, value) {
    state.embeddedURL = value;
  },
};

export const actions = {
  getList(context) {
    context.commit("CLEAR_DATA");
    context.commit("SET_LOADING_BOARDS_STATUS", true);

    return api
      .getDashboardList()
      .then((response) => {
        context.commit("SET_BOARDS", response.data.dashboards);
        context.commit("SET_LOADING_BOARDS_STATUS", false);
      })
      .catch(() => {
        context.commit("SET_LOADING_BOARDS_STATUS", false);
      });
  },

  getEmbeddedURL(context, code) {
    context.commit("SET_RETRIEVING_EMBEDDED_URL", true);

    return api
      .getDashboardEmbeddedURL(code)
      .then((response) => {
        context.commit("SET_EMBEDDED_URL", response.data);
        context.commit("SET_RETRIEVING_EMBEDDED_URL", false);
      })
      .catch(() => {
        context.commit("SET_RETRIEVING_EMBEDDED_URL", false);
      });
  },
};

export const getters = {
  getDashboards(state) {
    return state.boards;
  },

  getCurrentEmbeddedURL(state) {
    return state.currentEmbeddedURL;
  },
};
