import axios from "axios";
import api from "@/services/api.js";

export const namespaced = true;

export const state = {
  user: null,
};

export const mutations = {
  SET_USER_DATA(state, userData) {
    state.user = userData;

    if (!hasOwnProperty.call(userData, "timestamp")) {
      userData.timestamp = new Date().getTime();
    }

    localStorage.setItem("user", JSON.stringify(userData));
    localStorage.setItem("last_user", state.user.email);
    axios.defaults.headers.common["Authorization"] = `Bearer ${userData.token}`;
  },

  CLEAR_USER_DATA(state) {
    localStorage.removeItem("user");
    state.user = null;
    axios.defaults.headers.common["Authorization"] = "";
  },
};

export const actions = {
  login({ commit }, credentials) {
    return api.login(credentials).then(({ data }) => {
      commit("SET_USER_DATA", data);
    });
  },

  clearUserData({ commit }) {
    commit("CLEAR_USER_DATA");
  },

  signout({ commit }) {
    if (this.state.user) {
      api.signout().then(() => {
        commit("CLEAR_USER_DATA");
      });
    }
  },

  changePassword({ commit }, payload) {
    let cfg = null;
    if (payload.config) {
      cfg = payload.config;
      delete payload.config;
    }
    return api.changePassword(payload, cfg).then(() => {
      commit("CLEAR_USER_DATA");
    });
  },

  resetPassword({ commit }, payload) {
    return api.resetPassword(payload).then(() => {
      commit("CLEAR_USER_DATA");
    });
  },
};

export const getters = {
  loggedIn(state) {
    return !!state.user;
  },

  passwordChangeRequired(state) {
    return state.user.changePassword;
  },

  userToken(state) {
    return state.user ? state.user.token : "";
  },

  getUser(state) {
    return state.user;
  },
};
