<template>
  <nav>
    <v-app-bar app dark color="grey darken-2">
      <v-app-bar-nav-icon
        @click.stop="handleHamburgerClick()"
      ></v-app-bar-nav-icon>
      <v-img contain max-width="195" max-height="39" :src="sproutLogo"></v-img>
      <v-toolbar-title data-cy="appTitle"> {{ appTitle }}</v-toolbar-title>
      <v-spacer></v-spacer>

      <v-menu left bottom>
        <template v-slot:activator="{ on }">
          <v-btn icon v-on="on">
            <v-icon>mdi-dots-vertical</v-icon>
          </v-btn>
        </template>

        <v-list>
          <v-list-item v-if="!isLoggedIn" @click="login">
            <v-list-item-title>Login</v-list-item-title>
          </v-list-item>

          <v-list-item v-if="!isLoggedIn" @click="signup">
            <v-list-item-title>Signup</v-list-item-title>
          </v-list-item>

          <v-list-item v-if="isLoggedIn" @click="changePassword">
            <v-list-item-title>Change password</v-list-item-title>
          </v-list-item>

          <v-list-item v-if="isLoggedIn" @click="signOut">
            <v-list-item-title>Signout</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
    </v-app-bar>

    <v-navigation-drawer app v-model="showDrawer" class="primary white--text">
      <v-list nav expand>
        <v-list-item v-if="isLoggedIn" @click="routeTo('UploadServer')">
          <v-list-item-title class="white--text"
            >Upload Server</v-list-item-title
          >
        </v-list-item>

        <v-list-item v-if="isLoggedIn" @click="routeTo('BatchInfo')">
          <v-list-item-title class="white--text">Batch Info</v-list-item-title>
        </v-list-item>

        <v-list-item v-if="isLoggedIn" @click="routeTo('Pubhub')">
          <v-list-item-title class="white--text">PubHub</v-list-item-title>
        </v-list-item>

        <!-- Pending sales -->
        <v-list-group v-if="isLoggedIn" :value="active" no-action>
          <template v-slot:activator>
            <v-list-item-content>
              <v-list-item-title class="white--text"
                >Pending Sales</v-list-item-title
              >
            </v-list-item-content>
          </template>

          <v-list-item @click="routeTo('PendingSales')">
            <v-list-item-content>
              <v-list-item-title class="white--text">Summary</v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          <v-list-item @click="routeTo('SaleProcessingIssue')">
            <v-list-item-content>
              <v-list-item-title class="white--text"
                >All issues</v-list-item-title
              >
            </v-list-item-content>
          </v-list-item>

          <v-list-item @click="routeTo('CustomerMappingIssue')">
            <v-list-item-content>
              <v-list-item-title class="white--text"
                >Customer Mapping</v-list-item-title
              >
            </v-list-item-content>
          </v-list-item>

          <v-list-item @click="routeTo('ItemMappingIssue')">
            <v-list-item-content>
              <v-list-item-title class="white--text"
                >Item Mapping</v-list-item-title
              >
            </v-list-item-content>
          </v-list-item>
        </v-list-group>

        <!-- Pending resolutions -->
        <v-list-group v-if="isLoggedIn" :value="active" no-action>
          <template v-slot:activator>
            <v-list-item-content>
              <v-list-item-title class="white--text"
                >Pending Resolutions</v-list-item-title
              >
            </v-list-item-content>
          </template>

          <v-list-item @click="routeTo('CustomerMappingResolutions')">
            <v-list-item-content>
              <v-list-item-title class="white--text"
                >Customer mapping</v-list-item-title
              >
            </v-list-item-content>
          </v-list-item>
        </v-list-group>

        <v-divider></v-divider>

        <v-list-item v-if="isLoggedIn" @click="routeTo('dashboard')">
          <v-list-item-title class="white--text">Dashboards</v-list-item-title>
        </v-list-item>

        <v-divider></v-divider>

        <v-list-item v-if="isLoggedIn" @click="routeTo('TheaterContract')">
          <v-list-item-title class="white--text"
            >Theater Contract</v-list-item-title
          >
        </v-list-item>

        <v-divider></v-divider>

        <v-list-item v-if="isLoggedIn" @click="routeTo('ChangeLog')">
          <v-list-item-title class="white--text">Change Log</v-list-item-title>
        </v-list-item>

        <!-- Testing -->
        <v-list-group v-if="isLoggedIn" :value="active" no-action>
          <template v-slot:activator>
            <v-list-item-content>
              <v-list-item-title class="white--text">Testing</v-list-item-title>
            </v-list-item-content>
          </template>
        </v-list-group>
      </v-list>
    </v-navigation-drawer>
  </nav>
</template>

<script>
import api from "@/services/api.js";

export default {
  data: function () {
    return {
      showDrawer: false,
      sproutLogo: require("@/assets/full_logo.png"),
      active: false,
      active2: false,
    };
  },

  computed: {
    isLoggedIn() {
      return this.$store.getters["user/loggedIn"];
    },

    showTestingLink() {
      let u = this.$store.getters["user/getUser"];
      return u && u.email === "david.saracini@sproutretail.com";
    },

    appTitle() {
      return process.env.VUE_APP_TITLE;
    },

    isDark() {
      return this.$vuetify.theme.isDark;
    },
  },

  methods: {
    signOut() {
      this.$store.dispatch("user/signout");
      this.$router.push({ name: "goodbye" });
      this.showDrawer = false;
    },

    // testMsgBox() {
    //   this.$router.push({ name: "testMsgBox" });
    // },

    login() {
      this.$router.push({ name: "login" });
    },

    signup() {
      this.$router.push({ name: "signup" });
    },

    changePassword() {
      this.$router.push({ name: "changePassword" });
    },

    setTheme(value) {
      this.$vuetify.theme.dark = value;
      this.$eventHub.$emit("ui-theme-changed", value);
      localStorage.setItem("ui", JSON.stringify({ dark: value }));
    },

    routeTo(value, params) {
      if (this.$route.name !== value) {
        this.$router.push({ name: value, params: params });
      }
    },

    handleHamburgerClick() {
      if (!this.showDrawer && !this.isLoggedIn) {
        api.checkToken().then(() => {
          this.showDrawer = true;
          return;
        });
      }

      this.showDrawer = !this.showDrawer;
    },
  },

  mounted() {
    const ui = this.$store.getters["ui/getOptions"];
    this.showDrawer = ui.showDrawer && this.isLoggedIn;
  },

  beforeDestroy() {
    this.$store.dispatch("ui/setOptions", { showDrawer: this.showDrawer });
  },
};
</script>

<style>
.v-list .v-list-item--active .v-icon {
  color: white !important;
}

.v-list-group__items .v-list-item {
  padding-left: 42px !important;
}
</style>
