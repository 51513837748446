import api from "@/services/api.js";

export const namespaced = true;

export const state = {
  loading: false,
  serverData: [],
  publisherResults: null,
  publisherRunning: false,
};

export const mutations = {
  SET_LOADING_STATUS(state, value) {
    state.loading = value;
  },

  SET_UPLOAD_SERVER_DATA(state, data) {
    data.forEach((rec) => {
      if (rec.last_run === "unknown" || rec.last_run === "disabled") {
        rec.last_run = null;
      }
    });

    state.serverData = data;
  },

  CLEAR_DATA(state) {
    state.loading = false;
    state.serverData = [];
  },
};

export const actions = {
  getStatus(context) {
    context.commit("CLEAR_DATA");
    context.commit("SET_LOADING_STATUS", true);

    return api
      .getUploadStatus()
      .then((response) => {
        context.commit("SET_UPLOAD_SERVER_DATA", response.data.pipelines);
        context.commit("SET_LOADING_STATUS", false);
      })
      .catch(() => {
        context.commit("SET_LOADING_STATUS", false);
      });
  },

  clearServerStatus(context) {
    context.commit("CLEAR_DATA");
  },
};

export const getters = {
  isLoading() {
    return state.loading;
  },

  getData() {
    return state.serverData;
  },

  getPublisherResults() {
    return state.publisherResults;
  },

  publisherIsRunning() {
    return state.publisherRunning;
  },
};
